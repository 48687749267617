<template>
	<div class="collection-cases">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="col-main-box">
			<Sidder :tab_selectIndex='tab_selectIndex'></Sidder>
			<div class="col-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/'  }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>{{chinese_name}}</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="top-box" :class="hide? 'hidediv':'showdiv'">
					<div class="top-item-list">
						<div class="list-title">分类</div>
						<div class="list-info" :class="classify_select == '全部'?'list-active':''" @click="changeclassify('全部')">全部
						</div>
						<div class="list-info" v-for="(item,index) in classifyArr" :key="index" v-if="fl_show"
							:class="classify_select == item?'list-active':''" @click="changeclassify(item)">{{item}}
						</div>
					</div>
					<div class="top-item-list">
						<div class="list-title">类型</div>
						<!-- <div class="list-info" :class="type_select == '0'?'list-active':''" @click="changetype('0',0)">全部</div> -->
						<div class="list-info" v-for="(item,index) in typeArr" :key="item.id"
							:class="type_select == index?'list-active':''" @click="changetype(index,item.id)">
							{{item.name}}</div>
					</div>
				</div>
				<div class="relust" :class="hide? 'showdiv':''">搜索结果：</div>
				<!-- 有数据时 -->
				<div class="all-list-box" v-if="HaveData">
					<div class="list-item" v-for="item in dataList"
						@click="changepage(item.id,item.name,item.goods_img,item.video)">
						<div class="img-container">
							<img :src="item.image" alt="">
						</div>
						<div class="item-name">{{item.name}}</div>
					</div>
				</div>
				<el-pagination v-if="HaveData" @current-change='handleCurrentChange' background
					layout="prev, pager, next" :page-size='pageSize' :total="total" next-text="下一页">
				</el-pagination>
				<!-- 无数据时 -->
				<div v-else class="nodata">
					<img src="../../assets/images/sidder/zanwuneirong.png" alt="">
					暂无数据~
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BannerOther from '@/components/banner-other/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	import {
		getanlileixingList,
		getanliList
	} from "@/api/joggle.js"
	import {
		getycdaohang,
	} from '@/api/api.js'
	export default {
		name:'Collection-Cases',
		props: ['keyword'],
		components: {
			BannerOther,
			Sidder
		},
		data() {
			return {
				tab_selectIndex: 3,
				img_url: '',
				chinese_name: '案例锦集',
				english_name: 'COLLECTION OF CASES',
				typeArr: [], //类型列表
				type_select: 0,
				classifyArr: ['微电影', '纪录片', '婚礼旅拍', '商业拍摄', '企业宣传片', '政府宣传片'],
				classify_select: '',
				category_id: '', //分类id
				page_no: 1, //当前页
				pageSize: 16, //每页显示个数
				total: 0, //总条数
				dataList: [], //数据列表
				series_id: '', //类型id
				top_keyword: '',
				hide: false,
				HaveData: true,
				fl_show:false
			}
		},
		watch: {
			keyword(val, oldval) {
				var that = this
				var index = that.$route.query.siderIndex;
				var name = that.$route.query.typename;
				that.classify_select = name;
				if (index == 4) {
					that.category_id = 1;
				}
				if (index == 5) {
					that.category_id = 2;
				}
				if (index == 6) {
					that.category_id = 3;
				}
				if (index == 7) {
					that.category_id = 4;
				}
				if (index == 8) {
					that.category_id = 5;
				}
				if (index == 9) {
					that.category_id = 6;
				}
				localStorage.setItem('classify_select', name)
				localStorage.setItem('category_id', that.category_id)
				that.top_keyword = val;
				that.page_no = 1;
				that.series_id = '';
				if (that.top_keyword == '') {
					that.getList(that.series_id);
				} else {
					that.getList(that.series_id, '搜索');
				}
			},
			$route(to, from) {
				this.$router.go(0);
			}
		},
		activated() {
			var that = this
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if (company_info) {
				that.img_url = company_info.anli_image;
			}
			that.top_keyword = that.$route.query.top_keyword;
			if (!that.top_keyword) {
				that.top_keyword = ''
			}
			// 侧边栏选中项
			that.tab_selectIndex = that.$route.query.siderIndex;
			if (that.tab_selectIndex == 3) {
				that.chinese_name = '案例锦集';
				that.english_name = 'COLLECTION OF CASES';
				document.title = '案例锦集-MG影像'; //修改浏览器名称

			}
			if (that.tab_selectIndex == 4) {
				that.chinese_name = '微电影';
				that.english_name = 'MICROFILM';
				document.title = '微电影-MG影像';

			}
			if (that.tab_selectIndex == 5) {
				that.chinese_name = '婚礼旅拍';
				that.english_name = 'WEDDING SHOOTING';
				document.title = '婚礼旅拍-MG影像';

			}
			if (that.tab_selectIndex == 6) {
				that.chinese_name = '商业拍摄';
				that.english_name = 'COMMERCIAL SHOOTING';
				document.title = '商业拍摄-MG影像';

			}
			if (that.tab_selectIndex == 7) {
				that.chinese_name = '企业宣传片';
				that.english_name = 'CORPORATE PROMOTIONAL FILM';
				document.title = '企业宣传片-MG影像';

			}
			if (that.tab_selectIndex == 8) {
				that.chinese_name = '纪录片';
				that.english_name = 'DOCUMENTARY';
				document.title = '纪录片-MG影像';

			}
			if (that.tab_selectIndex == 9) {
				that.chinese_name = '政府宣传片';
				that.english_name = 'GOVERNMENT PROMOTIONAL FILM';
				document.title = '政府宣传片-MG影像';

			}
			// 首先判断是否第一次进入该页面
			var intoIndex = localStorage.getItem('operate');
			if (intoIndex == '首次') {
				console.log(11)
				// 分类选中项
				that.classify_select = that.$route.query.typename;
				if (that.tab_selectIndex == 3) {
					that.category_id = 0;
					that.classify_select = '全部';
				}
				if (that.tab_selectIndex == 4) {
					that.category_id = 1;
				}
				if (that.tab_selectIndex == 5) {
					that.category_id = 2;
				}
				if (that.tab_selectIndex == 6) {
					that.category_id = 3;
				}
				if (that.tab_selectIndex == 7) {
					that.category_id = 4;
				}
				if (that.tab_selectIndex == 8) {
					that.category_id = 5;
				}
				if (that.tab_selectIndex == 9) {
					that.category_id = 6;
				}
				that.getseriesList(that.category_id, intoIndex);
				that.type_select = 0;
			}
			if (intoIndex == '非首次') {
				// 分类选中项名称
				var classify = localStorage.getItem('classify_select');
				that.classify_select = classify;
				// 所选分类id
				var category_id = localStorage.getItem('category_id');
				that.category_id = category_id;
				// 类型选中项
				var type = localStorage.getItem('type_select');
				that.type_select = type;

				that.getseriesList(that.category_id, intoIndex);
			}
			// 获取侧边隐藏导航栏
			that.getyclist();
		},
		methods: {
			getyclist(){
				var that = this
				var obj = {}
				getycdaohang(obj).then(res => {
					if (res.code == 1) {
						var data = res.data;
						if(data.length > 0){
							data.forEach((item,index)=>{
								that.classifyArr = that.classifyArr.filter(item1=>item1 != item.name);
							})
						}
						that.fl_show = true;
					}
				})
			},
			// 获取类型列表
			getseriesList(category_id, intoIndex) {
				var that = this
				var obj = {
					category_id: category_id
				}
				getanlileixingList(obj).then(res => {
					if (res.code == 1) {
						that.typeArr = res.data;
						var obj = {
							id:0,
							name :'全部'
						}
						that.typeArr.splice(0,0,obj)
						if (that.typeArr.length > 0) {
							if (intoIndex == '首次') {
								that.series_id = 0;
							}
							if (intoIndex == '非首次') {
								var type = localStorage.getItem('type_select');
								that.series_id = that.typeArr[type].id;
							}
							that.getList(that.series_id);
						} else {
							that.series_id = '';
							that.dataList = [];
							that.HaveData = false;
						}
						// 获取数据
					} else {
						that.$message.error(res.msg);
					}

				})
			},
			// 获取数据列表
			getList(series_id, name) {
				var that = this
				if (that.top_keyword != '') {
					name = '搜索'
				} else {
					name = ''
				}
				var obj = {
					category_id: that.category_id,
					series_id: series_id,
					page_no: that.page_no,
					keyword: that.top_keyword
				}
				getanliList(obj).then(res => {
					if (res.code == 1) {
						if (name == '搜索') {
							that.hide = true;
						} else {
							that.hide = false;
						}
						that.total = res.data.count;
						that.pageSize = res.data.size;
						that.dataList = res.data.list;
						if (that.dataList.length == 0) {
							that.HaveData = false;
						} else {
							that.HaveData = true;
						}
					} else {
						that.$message.error(res.msg);
					}

				})
			},
			// 分页
			handleCurrentChange(val) {
				var that = this
				// 当前页
				that.page_no = val;
				that.getList(that.series_id);
			},
			changepage(id, name, goods_img,video) {
				var that = this
        if(goods_img == '' || goods_img == null){ //无图有视频
         window.open(video,'_blank')
        }else{ //有图
          that.$router.push({
          	path: '/case-details',
          	query: {
          		f_pagename: that.chinese_name,
          		name: name,
          		f_englishname: that.english_name,
          		id: id,
          		// url: url,
          		// shownum: shownum,
          		top_keyword: that.top_keyword
          	}
          })
        }


			},
			// 切换分类选中
			changeclassify(name) {
				console.log('22222222')

				var that = this
				that.classify_select = name;
				if (name == '全部') {
					that.category_id = 0;
				}

				if (name == '微电影') {
					// 控制侧边栏联动
					// that.tab_selectIndex = 5;
					that.category_id = 1;
				}
				if (name == '婚礼旅拍') {
					that.category_id = 2;
				}
				if (name == '商业拍摄') {
					that.category_id = 3;
				}
				if (name == '企业宣传片') {
					that.category_id = 4;
				}
				if (name == '纪录片') {
					that.category_id = 5;
				}
				if (name == '政府宣传片') {
					that.category_id = 6;
				}
				//保存所选择分类名称
				localStorage.setItem('classify_select', that.classify_select)
				// 保存所选分类的id
				localStorage.setItem('category_id', that.category_id)
				// 保存操作名称
				localStorage.setItem('operate', '非首次');
				// 重置类型所选择项
				that.type_select = 0;
				localStorage.setItem('type_select', 0);
				that.page_no = 1;
				that.getseriesList(that.category_id, '首次');
			},
			// 切换类型选中
			changetype(index, id) {
				var that = this
				that.type_select = index;
				// 保存所选类型的index
				localStorage.setItem('type_select', that.type_select);
				localStorage.setItem('operate', '非首次');
				that.page_no = 1;
				that.getList(id);
			}
		},
		// 离开页面事件
		beforeDestroy() {},
	}
</script>

<style lang="scss">
	@import '../../assets/css/public.scss';
	@import '../../assets/css/collection-cases.scss';
</style>
